<template>
  <b-modal
    id="packages-detail-view-modal"
    title="Package Details "
    centered
    hide-footer
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <div class="container" style="margin-bottom: 30px">
      <div style="margin-bottom: 30px">
        <b-row>
          <b-col md="12" v-if="plan">
            <h2 class="font-weight-bolder text-center text-colorBlue">
              {{ plan.name }}
              <span class="text-colorGreen"> ({{ plan.amount }} pkr)</span>
            </h2>

            <span>
              <h4 class="font-weight-bolder text-colorBlue">Description</h4>
              <p
                v-html="plan.description"
                class="font-weight-bolder text-colorText"
              ></p>
            </span>
            <img
              v-if="plan && plan.display_image"
              :src="plan.display_image"
              alt="package Image"
              class="img-fluid p-5"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from "vuex";
import util from "@/util.js";

export default {
  props: {
    showModal: Boolean,
    plan: Object,
  },

  data() {
    return {};
  },
  mixins: [util],

  methods: {
    ...mapActions({
      getProcedures: "appData/getProceduresPublic",
      addToCart: "appData/addToCart",
    }),
    resetModal() {},
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 100%;
  height: 100%;
}
.flex-container span {
  margin: 0px;
  margin-top: 0px;
  margin-left: 0px;
}
/* .prodcedures-heading{
    margin-bottom:0px;
    margin-top:20px;
    padding-bottom:0px;
  } */
</style>

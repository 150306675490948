<template>
  <div class="my-5">
    <b-container>
      <h2 class="font-weight-bolder text-colorBlue text-center pb-2">
        MedAsk Tours Packages
      </h2>
      <h5 class="font-weight-bold">
        Explore our comprehensive procedure packages, designed for international
        patients seeking top-quality care at affordable prices. Experience
        world-class treatment and personalized care with MedAsk Tours our
        medical services.
      </h5>
      <b-row class="mt-3">
        <b-col
          md="6"
          lg="3"
          v-for="(plan, index) in packages"
          :key="index"
          class="d-flex flex-column justify-content-between mb-2"
        >
          <div
            class="p-2 h-100"
            :class="{
              'bg-colorBlue': isHovered === index,
              'bg-colorGrey': isHovered !== index,
            }"
            style="border-radius: 20px"
            @mouseover="isHovered = index"
            @mouseleave="isHovered = null"
          >
            <div class="text-center h-25">
              <img
                :src="plan.display_image"
                class="w-100 h-100"
                style="border-radius: 20px"
                alt="image"
              />
            </div>
            <div class="h-75">
              <h4
                class="font-weight-bolder text-colorBlue pt-1"
                :class="{
                  'text-colorWhite': isHovered === index,
                  'text-colorBlue': isHovered !== index,
                }"
                style="height: 20%"
              >
                {{ plan.name }}
              </h4>
              <p
                v-html="plan.description"
                class="font-weight-bold text-dark multi-line-truncate"
                style="font-size: 1.1rem; height: 40%"
              ></p>
              <h4
                class="font-weight-bolder text-colorGreen py-1"
                style="height: 15%"
              >
                {{ plan.amount }}
                <span class="font-weight-normal text-colorGreen"> pkr</span>
              </h4>
              <div style="height: 10%" class="text-center pt-1">
                <b-button
                  pill
                  variant="colorGreen"
                  size="md"
                  class="text-light font-weight-bolder px-4"
                  @click="openProfileModal(plan)"
                >
                  Details
                </b-button>
              </div>
            </div>
          </div>

          <!-- <div class="text-center">
            <b-button
              block
              rounded
              :variant="isInCart(car) ? 'colorBlue' : 'colorGreen'"
              size="md"
              class="font-weight-bolder px-5 mb-sm-1"
              @click="handleCartAction(car)"
            >
              {{ isInCart(car) ? 'View Cart' : 'Add to Cart' }}
            </b-button>
          </div> -->
        </b-col>
      </b-row>
    </b-container>
    <PackagesDetailModal
      :plan="selectedPlan"
      @modalClosed="closeProfileModal"
      :key="`view-${packageViewModal}`"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PackagesDetailModal from "@/components/packages/PackagesDetailModal.vue";

export default {
  mixins: [util],
  components: {
    ToastificationContent,
    PackagesDetailModal,
  },
  data() {
    return {
      packages: [],
      selectedPlan: null,
      packageViewModal: 0,
      isHovered: false,
    };
  },
  computed: {
    ...mapState({
      cart: (state) => state.appData.cart,
    }),
  },
  async mounted() {
    try {
      // this.initializeCart();

      const res = await this.getHealthPackagesPublic({});
      if (res.status === 200) {
        this.packages = res.data;
      }
    } catch (error) {
      this.displayError(error);
    }
  },
  methods: {
    ...mapActions({
      getHealthPackagesPublic: "appData/getHealthPackagesPublic",
      addToCart: "appData/addToCart",
      removeFromCart: "appData/removeFromCart",
      // initializeCart: "appData/initializeCart",
    }),
    openProfileModal(plan) {
      this.packageViewModal += 1;
      this.selectedPlan = plan;
      this.$nextTick(() => {
        this.$bvModal.show("packages-detail-view-modal");
      });
    },
    closeProfileModal() {
      this.selectedPlan = null;
    },
    addCart(item) {
      this.addToCart({
        id: `${item.id}_${this.cartItemType.INTERPRETER}`,
        name: item.name,
        itemId: item.id,
        price: item.amount,
        type: this.cartItemType.INTERPRETER,
        quantity: 1,
        numberOfDays: 1,
      });
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Item added to cart successfully",
            variant: "success",
            icon: "BellIcon",
          },
        },
        {
          position: "top-right",
        }
      );
    },
    handleCartAction(item) {
      if (this.isInCart(item)) {
        this.$router.push({ name: "Cart" }); // Navigate to cart page
      } else {
        this.addCart(item);
      }
    },
    isInCart(item) {
      const cartItemId = `${item.id}_${this.cartItemType.INTERPRETER}`;
      return this.cart.some((cartItem) => cartItem.id === cartItemId);
    },
  },
};
</script>

<style scoped>
.bg-colorBlue:hover {
  background-color: #06bd46; /* Change to your desired green color */
}
.multi-line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5; /* Adjust line height if needed */
  max-height: calc(1.5em * 3); /* 1.5em * number of lines to show */
}
</style>
